var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "toggle" }, [
    _vm.label
      ? _c(
          "label",
          {
            class: { radio: _vm.radio },
            style: { width: _vm.computedWidth, color: _vm.computedLabelColor },
            attrs: { for: _vm.id },
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _vm._e(),
    _vm.radio
      ? _c("input", {
          class: _vm.classString,
          attrs: { id: _vm.id, type: "radio", disabled: _vm.disabled },
          domProps: {
            checked:
              _vm.value === (_vm.radioValue ? _vm.radioValue : _vm.label),
            value: _vm.radioValue ? _vm.radioValue : _vm.label,
          },
          on: { change: _vm.onInput },
        })
      : _c("input", {
          class: _vm.classString,
          attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
          domProps: { checked: _vm.value },
          on: { change: _vm.onInput },
        }),
    _vm.multiLabel
      ? _c("label", { class: { radio: _vm.radio }, attrs: { for: _vm.id } }, [
          _vm._v(_vm._s(_vm.multiLabel)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }