<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';
    input[type="radio"] {
        @include shadow(1, $inset: true);
        border: 1px solid $border_grey;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        transition: background-color $transition_short;
        &:checked {
            @include shadow(0);
            background:
                radial-gradient(circle, transparent 45%, white 55%, white),
                linear-gradient(190deg, rgba($white, 0.5) 20%, transparent,
                rgba($black, 0.25) 80%), radial-gradient(circle, $toggle_blue, $toggle_blue);
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;

        }
        &:disabled {
            border-color: transparentize($border_grey, 0.5);
            &:checked {
                background-color: $grey_light_3;
            }
        }
    }

    label:not(.radio), .label {
        width: 155px;
        display: inline-block;

        @include below(md) {
            display: block;
            width: auto;
            margin-bottom: 10px;
        }
    }

    .toggle {
        input[type="checkbox"] {
            cursor: pointer;
            &:not(.slider) {
                @include shadow(1, $inset: true);
                width: 16px;
                height: 16px;
                border: 1px solid $border_grey;
                border-radius: 2px;
                vertical-align: text-bottom;
                position: relative;
                transition: background-color $transition_short;

                &:after {
                    content: '';
                    display: none;
                    width: 8px;
                    height: 15px;
                    border-bottom: 4px solid $toggle_blue;
                    border-right: 4px solid $toggle_blue;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-20%, -70%) rotate(45deg);
                }

                &:checked {
                    &:after {
                        display: block;
                    }
                }
                &:disabled {
                    border-color: transparentize($border_grey, 0.5);
                    &:hover {
                        cursor: default;
                    }
                    &:checked {
                        background-color: $grey_light_3;
                    }
                }
            }
            &.slider {
                @include shadow(1, $inset: true);
                position: relative;
                background-color: rgba($shadow_color, 0.24);
                height: 16px;
                width: 42px;
                border-radius: 25px;
                transition: all 250ms;
                vertical-align: middle;
                margin: 2px;
                &.multi {
                    background-color: $brand_color;
                    &:before {
                        background-color: lighten($brand_color, 20) !important;
                    }
                }
                &:disabled {
                    background-color: transparentize($grey_light_3, 0.5) !important;
                    &:before {
                        background-color: $grey_light_3 !important;
                        box-shadow: none !important;
                    }
                }
                &:before {
                    @include shadow(2);
                    content: '';
                    position: absolute;
                    left: -4px;
                    top: -4px;
                    width: 24px;
                    height: 24px;
                    background-color: $white;
                    border-radius: 100%;
                    transition: all 250ms;
                    border: 1px solid $grey_light_3;

                }
                &:checked {
                    background-color: rgba($brand_color, 0.5);
                    &:before {
                        border-color: $brand_color;
                        background-color: $brand_color;
                        transform: translateX(100%);
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="toggle">
        <label :for="id" v-if="label" :class="{radio}" :style="{width: computedWidth, color: computedLabelColor}">{{ label }}</label>
        <input v-if="radio" :id="id" :class="classString" :checked="value === (radioValue ? radioValue : label)" :value="(radioValue ? radioValue : label)" @change="onInput" type="radio" :disabled="disabled">
        <input v-else :id="id" :class="classString" :checked="value" @change="onInput" type="checkbox" :disabled="disabled">
        <label :for="id" v-if="multiLabel" :class="{radio}">{{ multiLabel }}</label>
    </div>
</template>
<script>
export default {
    name: 'Toggle',
    data: () => ({
        id: `toggle-${Math.random().toString(36).substring(7)}`,
        defaultLabelWidth: '155px',
    }),
    computed: {
        classString() {
            return `${(this.multi || this.slider) ? 'slider' : ''} ${this.multi ? 'multi' : ''}`
        },
        computedWidth() {
            return this.labelWidth === undefined ? this.defaultLabelWidth : this.labelWidth
        },
        computedLabelColor() {
            return this.labelColor === undefined ? '#585858' : this.labelColor
        },
    },
    props: [
        'label',
        'value',
        'slider',
        'multi',
        'multiLabel',
        'disabled',
        'radio',
        'radioValue',
        'labelWidth',
        'labelColor',
    ],
    methods: {
        onInput(e) {
            this.$emit('input', this.radio ? e.target.value : e.target.checked)
        },
    },
}
</script>
